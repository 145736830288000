<template>
   
    <div> <h1>HELP Desk</h1>

    <v-progress-circular
        v-if="loading"
        indeterminate
        color="blue-grey"
    ></v-progress-circular>
        <v-card>
            <v-col>
                <v-data-table
                item-key="MessageId"
                show-select
                dense 
                 
                :headers="headerEmail"
                :items="itemsEmail"
                >
                <template v-slot:item.TextBodyB="{ item }">                
                    <v-icon @click="openModalDetail(item)">mdi-eye</v-icon>                          
                </template>
                <template v-slot:item.cargo="{ item }">
                     <s-select-generic></s-select-generic>
                </template>
                </v-data-table>
            </v-col>
        </v-card>
    <v-dialog 
        v-model="modalDetail">
       
         <v-card>
             <s-toolbar 
                 close
                 @close="modalDetail = false"/>
            <v-container>
                <div>
                    {{bodySelected}}                   
                </div>  
            </v-container>
        </v-card>
    </v-dialog>
    </div>
</template>
<script>

    import _sHelpDeskService from '@/services/InformationTechnology/HelpDesk';

    export default {
        data: () => ({
            modalDetail: false,
            headerEmail:[
                  
                 { text:"Emisor", value:"From", width:70 },
                 { text:"Asunto", value:"Subject"},
                 { text:"Body", value:"TextBodyB" , width:70 },
                 { text:"Encargado", value:"cargo" , width:70 },
            ],
            itemsEmail:[],
            loading: true,
            bodySelected: ""
        }),
        methods: {
            openModalDetail(item){
                this.bodySelected = item.TextBody;
                this.modalDetail = true;
                console.log("item.body", item);
            }
             
        },
        mounted(){
            _sHelpDeskService.list(this.$fun.getUserID())
            .then((r) => {
                if (r.status) {
                    console.log("email mensajes", r.data);
                    this.itemsEmail = r.data;
                    this.loading = false;
                }
            });
        }
    }
</script>

